import { Configurations } from '../configurations/configurations';
import { Services } from '../services/services';

export const DevDomains = Object.freeze({
  [Services.Admin]: 'https://admin.dev.profilum.tech',
  [Services.B2C]: 'https://talent.dev.profilum.tech',
  [Services.B2C_Redesigned]: 'https://proftestnr.dev.profilum.tech',
  [Services.B2G]: 'https://class.dev.profilum.tech',
  [Services.B2G_New]: 'https://b2g-new.dev.profilum.tech',
  [Services.Eurochem]: 'https://eurochem.dev.profilum.tech',
  [Services.Projects]: 'https://projects.dev.profilum.tech',
  [Services.Projects_Eng]: 'https://match-of-my-professions.dev.profilum.tech',
  [Services.Talent_Profilum]: 'https://dev.profilum.tech',
  [Services.Test_Editor]: 'https://testeditor.dev.profilum.tech',
  [Services.Vysokotech]: 'https://vysokotech.dev.profilum.tech',
  [Services.Ui_Kit]: 'https://class.dev.profilum.tech/ui-kit',
});

export const PreprodDomains = Object.freeze({
  [Services.Admin]: 'https://admin.pre.profilum.tech',
  [Services.B2C]: 'https://talent.pre.profilum.tech',
  [Services.B2C_Redesigned]: 'https://proftestnr.pre.profilum.tech',
  [Services.B2G]: 'https://class.pre.profilum.tech',
  [Services.B2G_New]: 'https://b2g-new.pre.profilum.tech',
  [Services.Eurochem]: 'https://eurochem.pre.profilum.tech',
  [Services.Projects]: 'https://projects.pre.profilum.tech',
  [Services.Projects_Eng]: 'https://match-of-my-professions.pre.profilum.tech',
  [Services.Talent_Profilum]: 'https://pre.profilum.tech',
  [Services.Test_Editor]: 'https://testeditor.pre.profilum.tech',
  [Services.Vysokotech]: 'https://vysokotech.pre.profilum.tech',
  [Services.Ui_Kit]: 'https://class.dev.profilum.tech/ui-kit', //dev only
});

export const ProdDomains = Object.freeze({
  [Services.Admin]: 'https://lk-admin.profilum.ru',
  [Services.B2C]: 'https://talent.profilum.ru',
  [Services.B2C_Redesigned]: 'https://proftestnr.profilum.ru',
  [Services.B2G]: 'https://class.profilum.ru',
  [Services.B2G_New]: 'https://b2g-new.profilum.ru', //not released
  [Services.Eurochem]: 'https://eurochem.profilum.ru',
  [Services.Projects]: 'https://projects.profilum.ru',
  [Services.Projects_Eng]: 'https://match-of-my-professions.profilum.career',
  [Services.Talent_Profilum]: 'https://profilum.ru',
  [Services.Test_Editor]: 'https://testeditor.profilum.ru',
  [Services.Vysokotech]: 'https://amkopros.tech',
  [Services.Ui_Kit]: 'https://class.dev.profilum.tech/ui-kit', //dev only
});

export const SwaggerDevDomain = 'https://internal.dev.profilum.tech';

export const SwaggerPreprodDomain = 'https://internal.pre.profilum.tech';

export const SwaggerProdDomain = 'https://internal.profilum.ru';

export const KevinSoftDevDomain = 'https://id.dev.profilum.tech';
export const KevinSoftPreprodDomain = 'https://demo.epoch.profilum.tech';
export const KevinSoftProdDomain = 'https://demo.epoch.profilum.ru';

export const DomainsByConfiguration: Record<string, typeof DevDomains | typeof PreprodDomains | typeof ProdDomains> = Object.freeze({
  [Configurations.Development]: DevDomains,
  [Configurations.PreProduction]: PreprodDomains,
  [Configurations.Production]: ProdDomains,
});

export const SwaggerDomainByConfiguration: Record<string, string> = Object.freeze({
  [Configurations.Development]: SwaggerDevDomain,
  [Configurations.PreProduction]: SwaggerPreprodDomain,
  [Configurations.Production]: SwaggerProdDomain,
});

export const KevinSoftDomainByConfiguration: Record<string, string> = Object.freeze({
  [DevDomains[Services.Eurochem]]: KevinSoftDevDomain,
  [PreprodDomains[Services.Eurochem]]: KevinSoftPreprodDomain,
  [ProdDomains[Services.Eurochem]]: KevinSoftProdDomain,
});
